.cart-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.cart-section .no-carts{
  text-align: center;
}

.cart-section h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.cart-section .cart-item {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-section .cart-item-details {
  flex: 1;
  padding-right: 20px;
}

.cart-section .cart-item-details p {
  margin: 8px 0;
  font-size: 16px;
  color: #444;
  line-height: 1.5;
}

.cart-section .cart-item-details p:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
  color: #222;
}

.cart-section .cart-item-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-section .cart-item-buttons .checkout-btn {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.cart-section .cart-item-buttons .checkout-btn:hover {
  background-color: #333;
}

.cart-section .cart-item-buttons .delete-btn {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.cart-section .cart-item-buttons .delete-btn:hover {
  background-color: #c9302c;
}

@media (max-width: 600px) {
  .cart-section .cart-item {
    flex-direction: column;
  }

  .cart-section .cart-item-details {
    margin-bottom: 10px;
    padding-right: 0;
  }

  .cart-section .cart-item-buttons {
    justify-content: center;
  }
}
