.signup-form-options {
  max-width: 100%; 
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.signup-form-options h2 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
  color: #333333;
}

.signup-form-options p, li {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: #666666;
}

.signup-form-options .options-content {
  margin: 20px;
  text-align: center;
}

.signup-form-options .options-buttons {
  margin: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.signup-form-options .options-buttons h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
}

.signup-form-options .button-option {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.signup-form-options .button-option:hover {
  background-color: #313131;
}

.signup-form-options .login-link {
  margin-top: 20px;
}

.signup-form-options .login-link a {
  color: #000000;
  text-decoration: none;
}

.signup-form-options .login-link a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .signup-form-options {
    max-width: 90%;
  }
}
