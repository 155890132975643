.change-password-container {
  max-width: 400px;
  margin: 20px auto;
  text-align: left;
}

.change-password-container .toggle-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s ease;
}

.change-password-container .toggle-button:hover {
  background-color: #313131;
}

.change-password-container .password-form {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.change-password-container .password-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.change-password-container .password-input {
  position: relative;
  margin-bottom: 10px;
}

.change-password-container .password-form input {
  width: 100%;
  padding: 14px;
  padding-right: 50px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.change-password-container .password-form input:focus {
  border-color: #007bff;
  outline: none;
}

.change-password-container .password-toggle-icon {
  font-size: 24px;
  color: #949494;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.change-password-container .change-password-button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.change-password-container .change-password-button:hover {
  background-color: #313131;
}

.change-password-container .change-password-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
