.my-account-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.my-account-container img.logo {
  width: 35%;
  max-width: 200px;
  margin-bottom: 20px;
}

.my-account-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
}

.action-buttons {
  margin-bottom: 20px;
}

.admin-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: linear-gradient(45deg, #ff6b6b, #f7d794);
  color: #fff;
  padding: 12px 24px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-right: 10px;
}

.admin-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.see-orders-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.see-orders-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.forms-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.profile-address-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .profile-address-container {
    flex-direction: column;
    align-items: center;
  }
}
