.checkout-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout-page h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.delivery-type-selector {
  margin-bottom: 20px;
}

.delivery-info {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.delivery-note {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
}

.addresses {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
}

.address-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 45%;
  margin: 10px;
  text-align: left;
}

.address-card h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 18px;
}

.address-card p {
  color: #555;
  font-size: 14px;
  margin: 5px 0;
}

.checkout-details {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.checkout-details p {
  margin: 5px 0;
  color: #555;
  font-size: 16px;
}

.checkout-details p strong {
  color: #000;
}

.payment-button button {
  background-color: #000;
  color: #fff;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.payment-button button:hover {
  background-color: #414141;
}

.payment-button button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.loading,
.no-details {
  text-align: center;
  font-size: 18px;
  color: #888;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .checkout-details {
    padding: 15px;
  }

  .checkout-details p {
    font-size: 14px;
  }

  .address-card {
    width: 90%;
  }
}
