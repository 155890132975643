.account-form {
    flex: 1;
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
  
  .account-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .account-form label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .account-form input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border 0.3s ease;
  }
  
  .account-form input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .account-form button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    transition: background-color 0.3s ease;
  }
  
  .account-form button:hover {
    background-color: #313131;
  }
  
  .account-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  