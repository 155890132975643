.container {
  max-width: 1200px;
  margin: 0 auto;
}

.admin-shop-list {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-shop-details .error-message {
  color: red;
  margin-bottom: 10px;
}

.admin-shop-edit form,
.admin-product-edit form {
  max-width: 600px;
  margin: 20px 0;
}

.admin-shop-edit label,
.admin-product-edit label {
  display: block;
  margin-bottom: 8px;
}

.admin-shop-edit input,
.admin-product-edit input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.admin-shop-edit button,
.admin-product-edit button {
  background-color: #000000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-shop-edit button:hover,
.admin-product-edit button:hover {
  background-color: #252525;
}

.admin-shop-edit .delete-btn,
.admin-product-edit .delete-btn {
  background-color: #d41811;
  color: white;
  padding: 10px 15px;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-shop-edit .delete-btn:hover,
.admin-product-edit .delete-btn:hover {
  background-color: #d9534f;
}

.admin-shop-edit .admin-shop-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 80px;
}

.admin-shop-edit .image-container {
  flex: 0 1 calc(50% - 10px);
  text-align: center;
  margin-bottom: 20px;
}

.admin-shop-edit .image-container img {
  width: 200px;
  height: 200px; 
  object-fit: cover; 
  margin-bottom: 10px;
}

.admin-shop-edit .image-title {
  font-weight: bold;
}

.admin-shop-edit .image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

summary::-webkit-details-marker {
  display: none;
}







.admin-shop-edit {
  display: flex;
  flex-wrap: wrap;
}

.form-container {
  flex: 1;
  margin-right: 20px; 
}

.admin-shop-images {
  flex: 1;
  margin-top: 20px; 
} 

.admin-product-edit .image-container {
  display: flex;
  flex-wrap: wrap;
}

.admin-product-edit .image-wrapper {
  flex: 0 1 calc(50% - 10px);
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .admin-shop-edit {
    flex-direction: column;
    margin-left: 0;
  }

  .admin-shop-images {
  flex: 1;
  margin-top: 20px; 
} 

.admin-shop-edit .admin-shop-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 0px;
}

.admin-shop-edit .image-container img {
  width: 100px;
  height: 100px; 
  object-fit: cover; 

}

  .form-container {
    margin-right: 0;
  } 

  .image-container {
    flex-direction: column;

  } 

  .image-wrapper {
    flex: 1 1 100%;
    margin-bottom: 0;
    padding: 10px;
  }
  
}


.admin-product-edit .toggle-label {
  display: flex;
  align-items: center;
}

.admin-product-edit .back {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.admin-product-edit .toggle {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  left: 2px;
  top: 2px;
}

.admin-product-edit input[type='checkbox']:checked + .back .toggle {
  transform: translateX(30px);
}

.admin-product-edit .label {
  display: none;
}

.admin-product-edit input[type='checkbox']:checked + .back {
  background-color: #4caf50;
}
