.payment-success {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .payment-success h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .payment-success p {
    margin: 10px 0;
    color: #555;
    font-size: 16px;
  }
  
  .payment-success p strong {
    color: #000;
  }
  
  .payment-success ul {
    list-style-type: none;
    padding-left: 0;
    margin: 10px 0;
  }
  
  .payment-success ul li {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .payment-success ul li::before {
    content: "• ";
    color: #000;
  }
  
  .payment-success button {
    background-color: #000;
    color: #fff;
    padding: 12px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 20px auto;
  }
  
  .payment-success button:hover {
    background-color: #414141;
  }
  
  .payment-success button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
  
  .payment-success .loading {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .payment-success {
      padding: 15px;
    }
  
    .payment-success p,
    .payment-success ul li {
      font-size: 14px;
    }
  }