.modal-icon {
    margin-bottom: 10px;
  }
  
  .modal-title {
    margin-bottom: 10px;
  }
  
  .modal-message {
    margin-bottom: 20px;
  }
  
  .modal-button {
    padding: 8px 16px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .modal-button:hover {
    background-color: #333;
  }
  
  .login-button {
    background-color: #007bff;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }


.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal content: Set a max-width and max-height to avoid full viewport coverage */
  .modal-content {
    max-width: 400px;
    max-height: 50vh;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    background-color: #fff;
    overflow-y: auto; /* Allows scrolling if content exceeds max-height */
  }
  
  /* Icon styling */
  .modal-icon {
    margin-bottom: 10px;
  }
  
  /* Modal internal styling */
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  .modal-buttons button {
    padding: 8px 16px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .modal-buttons button:hover {
    background-color: #333;
  }
  
  /* Additional styling for the login button */
  .login-button {
    background-color: #007bff;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  